import EPortfolio from './EPortfolio.png'
import SortingVisualizer from './SortingVisualizer.png'
import Unavailable from './Unavailable.png'
import API from './API.png'
import Transact from './transact_landing.jpg'
import Grace from './grace_templates.jpg'

export const projects = [
  {
    name: 'Transact',
    subtitle: 'A Personal Finance Tracker Application',
    technologies: ['React', 'Typescript', 'Dexiejs', 'TailwindCSS', 'shadcn/ui', 'Next15', 'Data Visualization', 'Personal Finance'],
    image: Transact,
    links: {
      github: 'https://github.com/ssanjose/transact',
      live: 'https://transact.kurtsanjose.dev/'
    },
  },
  {
    name: 'Personal Website',
    subtitle: 'My eportfolio website',
    technologies: ['React', 'Git', 'Material UI'],
    image: EPortfolio,
    links: {
      github: false,
      live: window.location.href,
    }
  },
  {
    name: 'Grace',
    subtitle: 'An open source card sending and receiving app. ',
    technologies: [
      "Next.js",
      "React",
      "TailwindCSS",
      "Shadcn/ui",
      "Golang",
      "Chi",
      "PostgreSQL",
      "Docker",
    ],
    image: Grace,
    links: {
      github: "https://github.com/gratefulness-app/grace",
      live: "https://grace.kurtsanjose.dev/",
    }
  },
  {
    name: 'NHSTA API',
    subtitle: 'API for the National Highway Traffic Safety Administration',
    technologies: ['Node', 'Express', 'Chai & Mocha', 'Heroku', 'TypeScript', 'Data Structures'],
    image: API,
    links: {
      github: 'https://github.com/ssanjose/nhtsa-api',
      live: 'https://nhtsa-api-assessment-312309210.herokuapp.com/',
    },
  },
  {
    name: 'Sorting Algorithm Visualizer',
    subtitle: 'A visualizer for sorting algorithms.',
    technologies: ['React', 'Vanilla CSS', 'Visualizing Techniques'],
    image: SortingVisualizer,
    links: {
      github: 'https://github.com/ssanjose/sortingalgovisualizertool',
      live: 'https://ssanjose.github.io/sortingalgovisualizertool/',
    },
  },
];
