import { AlternateMethod, ContactTitle, FooterContactContainer, FooterContainer, FooterMapContainer, FormContainer, FormText, MapDetails } from "../../customComponents/customFooter";
import 'mapbox-gl/dist/mapbox-gl.css';
import mapboxgl from "mapbox-gl";
import { useEffect, useRef } from "react";
import { Button, Typography } from "@mui/material";
import { useTheme } from "@mui/system";

import GitHubIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { link } from "framer-motion/client";

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;

const contactDetails = [
  {
    title: 'GitHub',
    link: 'https://github.com/ssanjose',
    icon: <GitHubIcon />,
  },
  {
    title: 'LinkedIn',
    link: 'https://www.linkedin.com/in/kurtsanjose/',
    icon: <LinkedInIcon />,
  }
];

export const Footer = () => {
  const theme = useTheme();
  const mapContainerRef = useRef();
  const map = useRef();

  useEffect(() => {
    if (map.current) return; // initialize map only once
    map.current = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [-97.15, 49.89],
      zoom: 11,
      interactive: false,
      "layers": [
        {
          "id": "water",
          "source": "mapbox-streets",
          "source-layer": "water",
          "type": "fill",
          "paint": {
            "fill-color": "#00ffff"
          }
        }
      ]
    });
  }, []);

  // use mailto: for email
  const submitHandler = (e) => {
    e.preventDefault();
    const form = e.target;

    const subject = form.subject.value;
    const description = form.description.value;

    console.log(subject, description);

    const a = document.createElement('a');
    a.href = `mailto:kurtsanjose58@gmail.com?subject=${subject}&body=${description}`;
    a.click();
  }

  return (
    <FooterContainer>
      <FooterContactContainer>
        <ContactTitle variant="h2" component="h2">
          Contact Me
        </ContactTitle>
        <FormContainer component="form" onSubmit={submitHandler}>
          <FormText
            id="subject"
            label="Subject"
            placeholder="Subject"
            required
          />
          <FormText
            id="description"
            label="Description"
            placeholder="Description"
            required
            multiline
            rows={4}
          />
          <Button
            component="button"
            type="submit"
            variant="contained"
          >
            Submit
          </Button>
        </FormContainer>
        <AlternateMethod>
          <p>
            You can also reach me through the following:
          </p>
          <ul>
            {contactDetails.map((contact, index) => (
              <li key={index}>
                <Button asChild>
                  <a href={contact.link} target="_blank" rel="noreferrer">
                    {contact.icon} {contact.title}
                  </a>
                </Button>

              </li>
            ))}
          </ul>
        </AlternateMethod>
      </FooterContactContainer>
      <FooterMapContainer ref={mapContainerRef}>
        <MapDetails>
          <Typography component="p" variant="body1" color={theme.palette.text.reversePrimary}>
            Symon Kurt San Jose,
            Winnipeg, MB
          </Typography>
        </MapDetails>
      </FooterMapContainer>
    </FooterContainer>
  )
};