import { Box, List, ListItem, ListItemButton, Typography } from "@mui/material";
import { styled } from "@mui/system";
import { DetailsContainer } from "./commonComponents";

export const NotableProjectsContainer = styled((props) => (<DetailsContainer
  {...props}
/>))(({ theme }) => ({
  margin: '0',
  background: 'linear-gradient(rgb(20, 24, 35), rgb(0, 0, 0))',
  width: '100%',

  [theme.breakpoints.up('xs')]: {
    padding: '5vh 0px',
  },
  [theme.breakpoints.up('md')]: {
    padding: '15vh 0px',
  },
}));

export const NotableProjectsBox = styled((props) => (<Box
  component='ul'
  {...props}
/>))(({ theme }) => ({
  listStyle: 'none',
  margin: '0px auto',
  width: '85%',
  position: 'relative',
  display: 'block',
  padding: '0',

  '& > li:not(:last-child)': {
    margin: '0 0 10% 0',
  },
}));

export const Featured = styled((props) => (<li
  {...props}
/>))(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  position: 'relative',
  top: '0px',
  width: '100%',
  justifyContent: 'space-between',
}));

export const FeaturedDescriptionSection = styled((props) => (<Box
  {...props}
/>))(({ theme }) => ({
  zIndex: '1',
  color: theme.palette.text.reversePrimary,

  '& *': {
    zIndex: '2',
  },

  [theme.breakpoints.up('xs')]: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '4vh 2vw',
    backgroundSize: 'cover',
    borderTop: 'none',
    width: '100%',

    '&::before': {
      content: '""',
      position: 'absolute',
      top: '0',
      left: '0',
      width: '100%',
      height: '100%',
      backgroundColor: '#171c28',
    },
  },
  [theme.breakpoints.up('md')]: {
    backgroundColor: 'transparent',
    padding: '2vh 2vw',
    width: 'fit-content',
    minWidth: '40%',
    maxWidth: '40%',
    minHeight: '100%',

    '&::before': {
      backgroundColor: 'transparent',
      width: '0px',
      height: '0px',
    },

    '&.left': {
      order: 1,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
    },

    '&.right': {
      order: 0,
      left: '0px',
    },

    '&.left, &.right': {
      position: 'relative',
      padding: '2vh 2vw',
      backgroundColor: 'rgba(0, 0, 0, 0.3)',
      borderTop: 'none',
    },

    '&::before': {
      backgroundColor: '#171c28',
    },
  },
}));

export const FeaturedBodyText = styled((props) => (<Typography
  {...props}
/>))(({ theme }) => ({
  marginBottom: '5px',
}));

export const FeaturedSkillArea = styled((props) => (<Box
  component="ul"
  {...props}
/>))(({ theme, left }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  justifyContent: 'flex-start',
  listStyle: 'none',
  padding: '0',
  marginBottom: '10px',
  width: 'fit-content',

  '& li:not(:last-child)': {
    marginRight: '10px',
    marginLeft: '0px',
  },

  [theme.breakpoints.up('md')]: {
    justifyContent: left ? 'flex-end' : 'flex-start',
    '& li:not(:last-child)': {
      marginRight: left ? '0px' : '10px',
      marginLeft: left ? '10px' : '0px',
    },
    '& li:last-child': {
      marginLeft: left ? '10px' : "0px",
    },
  },
}));

export const FeaturedSkill = styled((props) => (<Box
  component="li"
  {...props}
/>))(({ theme, left }) => ({
  fontSize: '0.8rem',
  width: 'fit-content',

  '&::before': {
    content: '"▸ "',
  },
  '&::after': {
    content: '""',
  },

  [theme.breakpoints.up('md')]: {
    '&::before': {
      content: left ? '""' : '"▸ "',
    },
    '&::after': {
      content: left ? '" ◂"' : '""',
    },
  },
}));

export const LinkList = styled((props) => (<List
  {...props}
/>))(({ theme }) => ({
  width: 'fit-content',
  display: 'flex',
  padding: '0',
  gap: '10px',
}));

export const LinkItem = styled((props) => (<ListItem
  {...props}
/>))(({ theme }) => ({
  width: 'fit-content',
  color: 'black',
  padding: '0',
}));

export const LinkItemButton = styled((props) => (<ListItemButton
  {...props}
/>))(({ theme }) => ({
  width: 'fit-content',
  fontSize: '0.8rem',
  color: 'rgba(83, 215, 255, 1)',
  border: `none`,
  backgroundColor: 'transparent',

  icon: {
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  svg: {
    color: 'inherit',
    paddingLeft: '5px',
  },
  div: {
    color: 'inherit',
    fontSize: 'inherit',
    minWidth: 'fit-content',
  },

  [theme.breakpoints.up('md')]: {
    fontSize: '1rem',
  },
}));

export const FeaturedPictureSection = styled((props) => (<Box
  {...props}
/>))(({ theme }) => ({
  backgroundColor: 'lightblue',
  height: 'fit-content',
  lineHeight: '0',

  [theme.breakpoints.up('xs')]: {
    display: 'none',
  },
  [theme.breakpoints.up('md')]: {
    display: 'block',
    position: 'relative',
    width: '58%',
    pointerEvents: 'initial',

    '::before': {
      content: '""',
      lineHeight: '0',
      display: 'inline-block',
      position: 'absolute',
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0,0,0, 0.1)',
    },
  },
}));

export const FeaturedImage = styled('img')(({ theme }) => ({
  width: '100%',
}));

export const FeaturedTitle = styled((props) => (<Typography
  {...props}
/>))(({ theme }) => ({
  fontSize: '1.5rem',
  fontWeight: 'bold',
}));

export const FeaturedNumber = styled((props) => (<Typography
  {...props}
/>))(({ theme }) => ({
  // yellow font color
  color: 'rgb(201, 201, 201)',
  textDecoration: 'underline',
  textUnderlineOffset: '2px',
}));